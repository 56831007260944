'use strict';

/* Polyfills */
if (typeof NodeList.prototype.forEach === "undefined") {
	NodeList.prototype.forEach = Array.prototype.forEach;
}
if (typeof HTMLCollection.prototype.forEach === "undefined") {
	HTMLCollection.prototype.forEach = Array.prototype.forEach;
}

/* App Class */
class App {

	constructor(){
		this.mainpage = !window.location.hash && window.location.pathname === '/';
		this.logo = document.querySelector('.header .logo') || false;
		this.nav = new Nav(document.querySelector('.header nav'), document.querySelector('[data-action="menu"]'), document.querySelector('.header'));
		this.ymap = new YMap(document.getElementById('map'));
	}

	init(){
		if (this.logo && !this.mainpage) {
			this.logo.classList.add('linked');
			this.logo.addEventListener('click', e => window.location = '/');
		};
		if (this.nav.el) this.nav.init();
		if (this.ymap.el) ymaps.ready(() => this.ymap.init());
		window.addEventListener('scroll', function() {
			const scrolled = window.scrollY, header = document.querySelector('.header');
			scrolled > 30 ? header.classList.add('header-fixed') : header.classList.remove('header-fixed');
		});
	}

}

/* Nav Class */
class Nav {

	constructor(el=false, control=false, panel=false){
		this.el = el;
		this.control = control;
		this.panel = panel;
		this.items = [];
	}

	init(){
		const items = this.el.querySelectorAll('a');

		if (items.length) items.forEach( item => {
			this.items.push(item);
			/*
			** Only prod
			*/
			if (location.href === item.href) item.classList.add('active');
		} );

		if (this.control && this.panel) this.control.addEventListener('click', e => {
			e.preventDefault();
			this.panel.classList.toggle('open');
		});

		window.addEventListener('resize', e => {
			this.panel.classList.remove('open');
		})

	}

}

/* Map Class */
class YMap {

	constructor(el=false){
		this.el = el;
		this.map = undefined
	}

	init(){
		this.map =  new ymaps.Map('map', {
			center: [57.52050706713064,41.2283415],
			zoom: 16,
			controls: [],
			behaviors: ['drag', 'scrollZoom']
		});
		let place = new ymaps.Placemark(this.map.getCenter(), {
			balloonContent: 'Diamant Auto'
		},
		{
			iconLayout: 'default#image',
			iconImageHref: '/assets/images/pin.png',
			iconImageSize: [36, 48],
			iconImageOffset: [-18, -24]
		});
		let zoom = new ymaps.control.ZoomControl({
			options: {
				size: "small"
			}
		});
		this.map.geoObjects.add(place);
		this.map.controls.add(zoom);
		this.showOnMap();
	}

	showOnMap(){
		const showBtn = document.querySelector('[data-action="showOnMap"]');
		if (showBtn) showBtn.addEventListener('click', e => {
			e.preventDefault();
			this.map.setCenter([57.52050706713064,41.2283415], 16);
		});
	}

}

const app = new App();

window.addEventListener('DOMContentLoaded', () => app.init());